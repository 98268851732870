.ball.scale {
  animation: ball-scale 2500ms infinite;
}

@keyframes ball-scale {
  0% {
    transform: scale(1);
  }
  48% {
    transform: scale(1.4);
  }
  96% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.1);
  }
}
